import { Flex, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const InvoiceRow = (props) => {
  const { name, price, variant, discount, total } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px">
        <Flex direction="column">
          <Text fontSize="md" color={textColor} minWidth="100%">
            {name}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {variant}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor}>
          {price}
        </Text>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor}>
          {discount ? discount + '%' : '-'}
        </Text>
      </Td>

      <Td pr="0px">
        <Text fontSize="md" color={textColor} textAlign="right">
          {total}
        </Text>
      </Td>
    </Tr>
  );
};

export default InvoiceRow;
