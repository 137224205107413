import React, { useState } from 'react';
import {
  Text,
  useColorModeValue,
  Flex,
  Input,
  Button,
  HStack,
  Radio,
  RadioGroup,
  Badge,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { addDays, endOfDay, isBefore, startOfDay } from 'date-fns';
import { orderAPI } from 'utils/orderAPI';

const TambahLayananAktif = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const secondaryTextColor = useColorModeValue('gray.500', 'white');

  const [values, setValues] = useState({
    namaPembeli: '',
    emailPembeli: '',
    namaProduk: '',
    kategori: '',
    harga: '',
    diskon: '',
    durasiLayanan: '',
    jenisLayanan: 'Normal',
    tanggalMulai: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSave = async () => {
    const {
      namaPembeli,
      emailPembeli,
      namaProduk,
      kategori,
      durasiLayanan,
      harga,
      diskon,
      jenisLayanan,
      tanggalMulai,
    } = values;

    if (!namaPembeli)
      return toast.warn('Nama Pembeli wajib diisi', { toastId: 'warn-input' });

    if (namaPembeli.trim().length < 2)
      return toast.warn('Nama Pembeli minimal 2 karakter', {
        toastId: 'warn-input',
      });

    if (!emailPembeli)
      return toast.warn('Email wajib diisi', { toastId: 'warn-input' });

    if (emailPembeli.trim().length < 6)
      return toast.warn('Email minimal 6 karakter', {
        toastId: 'warn-input',
      });

    if (!namaProduk)
      return toast.warn('Nama Produk wajib diisi', { toastId: 'warn-input' });

    if (namaProduk.trim().length < 6)
      return toast.warn('Nama Produk minimal 6 karakter', {
        toastId: 'warn-input',
      });

    if (!kategori)
      return toast.warn('Kategori wajib diisi', { toastId: 'warn-input' });

    if (kategori.trim().length < 3)
      return toast.warn('Kategori minimal 3 karakter', {
        toastId: 'warn-input',
      });

    if (!durasiLayanan)
      return toast.warn('Durasi Layanan wajib diisi', {
        toastId: 'warn-input',
      });

    const minDuration = jenisLayanan === 'Normal' ? 1 : 30;
    if (durasiLayanan < minDuration)
      return toast.warn(`Durasi Layanan minimal ${minDuration} hari`, {
        toastId: 'warn-input',
      });

    if (!harga)
      return toast.warn('Harga wajib diisi', { toastId: 'warn-input' });

    if (harga < 1000)
      return toast.warn('Harga minimal 1000', { toastId: 'warn-input' });

    if (diskon && diskon <= 0)
      return toast.warn('Diskon Normal minimal 1', {
        toastId: 'warn-input',
      });

    if (diskon && diskon > 99)
      return toast.warn('Diskon Normal maksimal 99', {
        toastId: 'warn-input',
      });

    if (!tanggalMulai)
      return toast.warn('Tanggal Mulai wajib diisi', {
        toastId: 'warn-input',
      });

    const currentTimestamp = Date.now();

    const timestampTanggalMulai = startOfDay(new Date(tanggalMulai)).getTime();
    const timestampTanggalAkhir = endOfDay(
      addDays(timestampTanggalMulai, durasiLayanan)
    ).getTime();

    if (
      isBefore(
        endOfDay(timestampTanggalMulai),
        startOfDay(currentTimestamp).getTime()
      )
    )
      return toast.warn('Tanggal Mulai minimal hari ini', {
        toastId: 'warn-input',
      });

    toast.dismiss();

    const data = {
      name: namaProduk,
      image: '',
      category: kategori,
      price: Number(harga),
      discount: Number(diskon),
      duration: Number(24 * durasiLayanan),
      expiredAt: Number(timestampTanggalAkhir),
      status: 'Layanan Aktif',
      subscribtion: jenisLayanan === 'Normal' ? false : true,
      variant: jenisLayanan === 'Normal' ? 'Normal' : 'Berlangganan',
      user: {
        id: emailPembeli,
        email: emailPembeli,
        name: namaPembeli,
      },
    };

    setIsLoading(true);
    const api = new orderAPI();
    const [error] = await api.add(data);
    setIsLoading(false);

    setValues({
      namaPembeli: '',
      emailPembeli: '',
      namaProduk: '',
      kategori: '',
      harga: '',
      diskon: '',
      durasiLayanan: '',
      jenisLayanan: 'Normal',
      tanggalMulai: '',
    });

    if (error)
      return toast.error('Gagal menambahkan layanan', {
        toastId: 'toast-result',
      });

    return toast.success('Berhasil menambahkan layanan', {
      toastId: 'toast-result',
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Pembeli
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.namaPembeli}
                  onChange={handleChange('namaPembeli')}
                  width="100%"
                  placeholder="Sabda Maulana"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Email
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.emailPembeli}
                  onChange={handleChange('emailPembeli')}
                  width="100%"
                  placeholder="sabda@raharja.info"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Produk
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama Produk
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.namaProduk}
                  onChange={handleChange('namaProduk')}
                  width="100%"
                  placeholder="Paket Layanan Server Plesk"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Kategori
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.kategori}
                  onChange={handleChange('kategori')}
                  width="100%"
                  placeholder="Server"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Jenis Layanan
                  </Text>
                </Flex>
                <RadioGroup value={values.jenisLayanan}>
                  <HStack spacing={4}>
                    <Radio
                      value="Normal"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisLayanan: 'Normal',
                        });
                      }}
                    >
                      Normal
                    </Radio>
                    <Radio
                      value="Berlangganan"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisLayanan: 'Berlangganan',
                        });
                      }}
                    >
                      Berlangganan
                    </Radio>
                  </HStack>
                </RadioGroup>
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Durasi Layanan
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.durasiLayanan}
                  onChange={handleChange('durasiLayanan')}
                  width="100%"
                  placeholder="14"
                />
                <Text fontSize="sm" mt={2} color={secondaryTextColor}>
                  * Durasi dalam hari
                </Text>
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Harga
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.harga}
                  onChange={handleChange('harga')}
                  width="100%"
                  placeholder="150000"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Text fontSize="md" as="b" mb={2}>
                  Diskon
                </Text>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.diskon}
                  onChange={handleChange('diskon')}
                  width="100%"
                  placeholder="25"
                />
              </Flex>

              <Flex direction="column" width="100%">
                <Flex direction="column" p={1} mt={4} width="100%">
                  <Flex align="center" mb={2}>
                    <Text fontSize="md" as="b">
                      Tanggal Mulai
                    </Text>
                    <Badge ml="1" fontSize="0.8em">
                      Wajib
                    </Badge>
                  </Flex>
                  <Input
                    type="date"
                    disabled={isLoading}
                    value={values.tanggalMulai}
                    onChange={handleChange('tanggalMulai')}
                    width="100%"
                  />
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <HStack sx={{ px: 4, ml: 'auto', mt: 5 }} spacing={4}>
          <NavLink to="/admin/layanan-aktif">
            <Button disabled={isLoading}>Batalkan</Button>
          </NavLink>
          <Button
            disabled={isLoading}
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </HStack>
      </Flex>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default TambahLayananAktif;
