import {
  getFirestore,
  getDoc,
  query,
  doc,
  updateDoc,
} from 'firebase/firestore';

class transactionAPI {
  async get(id) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'transactions', id));
      const res = await getDoc(docsRef);

      return [null, { id: res.id, ...res.data() }];
    } catch (error) {
      return [error, null];
    }
  }

  async update(id, newStatus, newDate) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'transactions', id));
      const res = await updateDoc(docsRef, {
        'payment.status': newStatus,
        'payment.expiredAt': newDate,
      });

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }
}

export { transactionAPI };
