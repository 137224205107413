import {
  Avatar,
  Badge,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

import DaftarPenggunaAction from 'components/Dropdowns/DaftarPenggunaAction';

const DaftarPenggunaRow = (props) => {
  const { avatar, name, email, role, status, onEdit, onDelete } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  const createBadge = (status) => {
    if (status === 'Aktif') {
      return (
        <Badge
          bg={'green.400'}
          color={'white'}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      );
    }

    return (
      <Badge
        bg={'red.400'}
        color={'white'}
        fontSize="16px"
        p="3px 10px"
        borderRadius="8px"
      >
        {status}
      </Badge>
    );
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar
            name={name}
            src={avatar}
            w="50px"
            borderRadius="12px"
            me="18px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {email}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {role}
        </Text>
      </Td>
      <Td>{createBadge(status)}</Td>
      <Td>
        <HStack spacing="24px">
          <DaftarPenggunaAction onEdit={onEdit} onDelete={onDelete} />
        </HStack>
      </Td>
    </Tr>
  );
};

export default DaftarPenggunaRow;
