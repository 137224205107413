import React from 'react';
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FiCpu, FiCreditCard, FiUser, FiShoppingCart } from 'react-icons/fi';
import { getTime, isSameMonth, isSameYear, subMonths } from 'date-fns';
import currency from 'currency.js';

import ChartPenjualan from 'components/Charts/ChartPenjualan';
import MiniStatistics from './components/MiniStatistics';
import SalesOverview from './components/SalesOverview';
import { useOrder } from 'hooks/useOrder';
import { useTransaction } from 'hooks/useTransaction';
import { useUser } from 'hooks/useUser';
import { formatDate } from 'utils/formatDate';

export default function Dashboard() {
  const order = useOrder();
  const transaction = useTransaction();
  const user = useUser();

  const calculatePrice = (price, discount) => {
    return discount ? price - (price * discount) / 100 : price;
  };

  const calculatePPN = (price, ppn, percentage = true) => {
    if (percentage) {
      return (price * ppn) / 100;
    } else {
      return ppn;
    }
  };

  const calculatePercent = (oldValue, newValue) => {
    return oldValue > 0
      ? (((newValue - oldValue) / Math.abs(oldValue)) * 100).toFixed(1)
      : null;
  };

  const createGroupedTransactionData = () => {
    if (transaction.data === 'loading') return null;
    if (!transaction.data) return null;
    if (transaction.data.length === 0) return null;

    const currentTimestamp = Date.now();

    const groupedTransactionData = transaction.data.reduce(
      (arr, { payment, product }) => {
        const month = formatDate(payment.createdAt, 'M');
        const year = formatDate(payment.createdAt, 'yyyy');
        const timestamp = getTime(payment.createdAt);

        if (payment.status === 'Transaksi Berhasil') {
          const subTotal = calculatePrice(product.price, product.discount);
          const ppn = payment.ppn
            ? {
                type: payment.ppn.type,
                value: payment.ppn.value,
              }
            : {
                type: 'Persen',
                value: 11,
              };

          const total = currency(subTotal, { precision: 0 }).add(
            calculatePPN(subTotal, ppn.value, ppn.type === 'Persen')
          ).value;

          if (!arr[month]) {
            arr[month] = {
              month,
              year,
              timestamp,
              sum: total,
              length: 1,
            };
          } else {
            arr[month].sum += total;
            arr[month].length += 1;
          }
        }

        return arr;
      },
      {}
    );

    return Object.values(groupedTransactionData)
      .sort((a, b) => a.timestamp - b.timestamp)
      .filter((row) => {
        const currentYear = formatDate(currentTimestamp, 'yyyy');
        return row.year === currentYear;
      });
  };
  const groupedTransactionData = createGroupedTransactionData();

  const createTransactionData = () => {
    if (!groupedTransactionData)
      return {
        prevMonth: { sum: 0, length: 0 },
        thisMonth: { sum: 0, length: 0 },
      };

    const today = Date.now();

    const filteredPrev = groupedTransactionData.filter((row) => {
      const prevMonth =
        isSameMonth(row.timestamp, subMonths(today, 1)) &&
        isSameYear(row.timestamp, subMonths(today, 1));

      return prevMonth;
    });

    const filteredCurrent = groupedTransactionData.filter((row) => {
      const currentMonth =
        isSameMonth(row.timestamp, today) && isSameYear(row.timestamp, today);

      return currentMonth;
    });

    return {
      prevMonth: {
        sum: filteredPrev[0]?.sum || 0,
        length: filteredPrev[0]?.length || 0,
      },
      thisMonth: {
        sum: filteredCurrent[0]?.sum || 0,
        length: filteredCurrent[0]?.length || 0,
      },
    };
  };
  const transactionData = createTransactionData();

  const createUserData = () => {
    if (user.data === 'loading') return null;
    return user.data;
  };
  const userData = createUserData();

  const activeOrder = () => {
    if (order.data === 'loading') return [];
    if (!order.data) return [];

    const filteredOrder = order.data.filter(
      (doc) => doc.status === 'Layanan Aktif'
    );
    return filteredOrder;
  };

  return (
    <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <MiniStatistics
          title={'Total Layanan Aktif'}
          amount={activeOrder().length}
          icon={<Icon h={6} w={6} as={FiCpu} color="white" />}
        />
        <MiniStatistics
          title={'Total Pengguna'}
          amount={userData ? userData.length : 0}
          icon={<Icon h={6} w={6} as={FiUser} color="white" />}
        />
        <MiniStatistics
          title={'Transaksi Bulan Ini'}
          amount={transactionData ? transactionData.thisMonth.length : 0}
          percentage={
            transactionData
              ? calculatePercent(
                  transactionData.prevMonth.length,
                  transactionData.thisMonth.length
                )
              : null
          }
          icon={<Icon h={6} w={6} as={FiCreditCard} color="white" />}
        />
        <MiniStatistics
          title={'Penjualan Bulan Ini'}
          amount={transactionData ? transactionData.thisMonth.sum : 0}
          percentage={
            transactionData
              ? calculatePercent(
                  transactionData.prevMonth.sum,
                  transactionData.thisMonth.sum
                )
              : null
          }
          icon={<Icon h={6} w={6} as={FiShoppingCart} color="white" />}
        />
      </SimpleGrid>

      <Grid templateColumns="1fr" gap="24px" mt={8}>
        {groupedTransactionData && (
          <SalesOverview
            title={'Riwayat Penjualan'}
            percentage={
              transactionData
                ? calculatePercent(
                    transactionData.prevMonth.sum,
                    transactionData.thisMonth.sum
                  )
                : null
            }
            chart={<ChartPenjualan data={groupedTransactionData} />}
          />
        )}
      </Grid>
    </Flex>
  );
}
