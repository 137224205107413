import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

const ProductContext = createContext();

const ProvideProduct = ({ children }) => {
  const productProvider = useProvideProduct();

  return (
    <ProductContext.Provider value={productProvider}>
      {children}
    </ProductContext.Provider>
  );
};

const useProduct = () => useContext(ProductContext);

const useProvideProduct = () => {
  const [data, setData] = useState('loading');

  const firestore = getFirestore();

  useEffect(() => {
    const docsRef = collection(firestore, 'products');

    const unsubscribe = onSnapshot(docsRef, (snapshot) => {
      if (snapshot.empty) return setData([]);

      const productData = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setData(productData);
    });

    return () => unsubscribe();
  }, []);

  return { data };
};

export { ProvideProduct, useProduct };
