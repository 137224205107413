import {
  getFirestore,
  query,
  doc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';

class orderAPI {
  async add(data) {
    try {
      const firestore = getFirestore();

      const docsRef = doc(firestore, 'orders', String(Date.now()));
      const res = await setDoc(docsRef, data);

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }

  async update(id, newStatus) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'orders', id));
      const res = await updateDoc(docsRef, { status: newStatus });

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }
}

export { orderAPI };
