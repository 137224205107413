import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import routes from 'routes.js';
import { useAuth } from 'hooks/useAuth';
import theme from 'theme/theme.js';

export default function Print() {
  const history = useHistory();
  const auth = useAuth();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (auth.user === 'loading') return null;
      if (!auth.user) return null;

      if (prop.category === 'admin') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/print') {
        return (
          <Route
            path={prop.layout + prop.path + '/:id'}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (auth.user) return;
    history.replace('/auth/masuk');
  }, [auth.user]);

  if (auth.user === 'loading') return null;
  if (!auth.user) return null;

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="/print" to="/admin/dashboard" />
      </Switch>
    </ChakraProvider>
  );
}
