import {
  Avatar,
  Badge,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

import DaftarLayananAction from 'components/Dropdowns/DaftarLayananAction';

const DaftarLayananRow = (props) => {
  const {
    image,
    name,
    normal,
    subscribtion,
    discountNormal,
    discountSubscribtion,
    category,
    status,
    onEdit,
    onDelete,
  } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = () => {
    onDelete();
  };

  const createBadge = (status) => {
    if (status === 'Aktif')
      return (
        <Badge
          bg="green.400"
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      );

    return (
      <Badge
        bg="red.400"
        color="white"
        fontSize="16px"
        p="3px 10px"
        borderRadius="8px"
      >
        {status}
      </Badge>
    );
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar
            name={name}
            src={image}
            w="50px"
            borderRadius="12px"
            me="18px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {category}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {normal || '-'}
        </Text>
        <Text fontSize="sm" color="gray.400" fontWeight="normal">
          {discountNormal === 0 ? '' : `Diskon ${discountNormal}%`}
        </Text>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {subscribtion || '-'}
        </Text>
        {discountSubscribtion && (
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {discountSubscribtion === 0
              ? ''
              : `Diskon ${discountSubscribtion}%`}
          </Text>
        )}
      </Td>

      <Td>{createBadge(status)}</Td>

      <Td>
        <HStack spacing="24px">
          <DaftarLayananAction onEdit={handleEdit} onDelete={handleDelete} />
        </HStack>
      </Td>
    </Tr>
  );
};

export default DaftarLayananRow;
