import React, { useState } from 'react';
import {
  Text,
  useColorModeValue,
  Flex,
  Input,
  Button,
  HStack,
  Radio,
  RadioGroup,
  Textarea,
  Badge,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { productAPI } from 'utils/productAPI';

const TambahLayanan = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const secondaryTextColor = useColorModeValue('gray.500', 'white');

  const [values, setValues] = useState({
    namaProduk: '',
    kategori: '',
    fotoProduk: '',
    keterangan: '',
    stok: '',
    durasiNormal: '',
    hargaNormal: '',
    diskonNormal: '',
    durasiBerlangganan: '',
    hargaBerlangganan: '',
    diskonBerlangganan: '',
    jenisPajak: 'Tidak Ada',
    pajakFlat: '',
    pajakPersen: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSave = async () => {
    const {
      namaProduk,
      kategori,
      fotoProduk,
      keterangan,
      stok,
      durasiNormal,
      hargaNormal,
      diskonNormal,
      durasiBerlangganan,
      hargaBerlangganan,
      diskonBerlangganan,
      jenisPajak,
      pajakFlat,
      pajakPersen,
    } = values;

    if (!namaProduk)
      return toast.warn('Nama Produk wajib diisi', { toastId: 'warn-input' });

    if (namaProduk.trim().length < 6)
      return toast.warn('Nama Produk minimal 6 karakter', {
        toastId: 'warn-input',
      });

    if (!kategori)
      return toast.warn('Kategori wajib diisi', { toastId: 'warn-input' });

    if (kategori.trim().length < 3)
      return toast.warn('Kategori minimal 3 karakter', {
        toastId: 'warn-input',
      });

    if (!fotoProduk)
      return toast.warn('Foto Produk wajib diisi', { toastId: 'warn-input' });

    if (!keterangan)
      return toast.warn('Keterangan wajib diisi', { toastId: 'warn-input' });

    if (keterangan.trim().length < 10)
      return toast.warn('Keterangan minimal 10 karakter', {
        toastId: 'warn-input',
      });

    if (!stok)
      return toast.warn('Stok Produk wajib diisi', { toastId: 'warn-input' });

    if (stok < 1)
      return toast.warn('Stok Produk minimal 1 produk', {
        toastId: 'warn-input',
      });

    if (!durasiNormal)
      return toast.warn('Durasi Normal wajib diisi', { toastId: 'warn-input' });

    if (durasiNormal < 1)
      return toast.warn('Durasi Normal minimal 1 hari', {
        toastId: 'warn-input',
      });

    if (!hargaNormal)
      return toast.warn('Harga Normal wajib diisi', { toastId: 'warn-input' });

    if (hargaNormal < 1000)
      return toast.warn('Harga Normal minimal 1000', { toastId: 'warn-input' });

    if (diskonNormal && diskonNormal <= 0)
      return toast.warn('Diskon Normal minimal 1s', {
        toastId: 'warn-input',
      });

    if (diskonNormal && diskonNormal > 99)
      return toast.warn('Diskon Normal maksimal 99', {
        toastId: 'warn-input',
      });

    if (durasiBerlangganan && durasiBerlangganan < 30)
      return toast.warn('Durasi Berlangganan minimal 30 hari', {
        toastId: 'warn-input',
      });

    if (durasiBerlangganan && durasiBerlangganan < durasiNormal)
      return toast.warn(
        'Durasi Berlangganan harus lebih besar dari Durasi Normal',
        {
          toastId: 'warn-input',
        }
      );

    if (durasiBerlangganan && !hargaBerlangganan)
      return toast.warn('Harga Berlangganan belum diisi', {
        toastId: 'warn-input',
      });

    if (hargaBerlangganan && !durasiBerlangganan)
      return toast.warn('Durasi Berlangganan belum diisi', {
        toastId: 'warn-input',
      });

    if (hargaBerlangganan && hargaBerlangganan < 1000)
      return toast.warn('Harga Berlangganan minimal 1000', {
        toastId: 'warn-input',
      });

    if (diskonBerlangganan && diskonBerlangganan <= 0)
      return toast.warn('Diskon Berlangganan minimal 1', {
        toastId: 'warn-input',
      });

    if (diskonBerlangganan && diskonBerlangganan > 99)
      return toast.warn('Diskon Berlangganan maksimal 99', {
        toastId: 'warn-input',
      });

    if (jenisPajak === 'Persen' && !pajakPersen)
      return toast.warn('Pajak wajib diisi', { toastId: 'warn-input' });

    if (jenisPajak === 'Persen' && pajakPersen <= 0)
      return toast.warn('Pajak minimal 1', { toastId: 'warn-input' });

    if (jenisPajak === 'Flat' && !pajakFlat)
      return toast.warn('Pajak wajib diisi', { toastId: 'warn-input' });

    if (jenisPajak === 'Flat' && pajakFlat < 0)
      return toast.warn('Pajak tidak boleh kurang dari 0', {
        toastId: 'warn-input',
      });

    toast.dismiss();

    const variants = hargaBerlangganan
      ? ['Normal', 'Berlangganan']
      : ['Normal'];

    const descriptions = hargaBerlangganan
      ? [keterangan, keterangan]
      : [keterangan];

    const prices = hargaBerlangganan
      ? [Number(hargaNormal), Number(hargaBerlangganan)]
      : [Number(hargaNormal)];

    const discounts = hargaBerlangganan
      ? [Number(diskonNormal) || 0, Number(diskonBerlangganan) || 0]
      : [Number(diskonNormal) || 0];

    const durations = hargaBerlangganan
      ? [durasiNormal * 24, durasiBerlangganan * 24]
      : [durasiNormal * 24];

    let tipePajak = 'Tidak Ada';
    let totalPajak = 0;

    if (jenisPajak === 'Tidak Ada') {
      tipePajak = 'Flat';
      totalPajak = Number(0);
    }

    if (jenisPajak === 'Persen') {
      tipePajak = 'Persen';
      totalPajak = Number(pajakPersen);
    }

    if (jenisPajak === 'Flat') {
      tipePajak = 'Flat';
      totalPajak = Number(pajakFlat);
    }

    const data = {
      name: namaProduk,
      category: kategori,
      image: fotoProduk,
      variants,
      descriptions,
      prices,
      discounts,
      durations,
      ppn: {
        type: tipePajak,
        total: totalPajak,
      },
      stock: Number(stok),
    };

    setIsLoading(true);

    const api = new productAPI();
    const [error] = await api.add(data);

    setIsLoading(false);

    setValues({
      namaProduk: '',
      kategori: '',
      fotoProduk: '',
      keterangan: '',
      stok: '',
      durasiNormal: '',
      hargaNormal: '',
      diskonNormal: '',
      durasiBerlangganan: '',
      hargaBerlangganan: '',
      diskonBerlangganan: '',
      jenisPajak: 'Tidak Ada',
      pajakFlat: '',
      pajakPersen: '',
    });

    if (error)
      return toast.error('Gagal menambahkan produk', {
        toastId: 'toast-result',
      });

    return toast.success('Berhasil menambahkan produk', {
      toastId: 'toast-result',
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Produk
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama Produk
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.namaProduk}
                  onChange={handleChange('namaProduk')}
                  width="100%"
                  placeholder="Paket Layanan Server Plesk"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Kategori
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.kategori}
                  onChange={handleChange('kategori')}
                  width="100%"
                  placeholder="Server"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Foto Produk
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.fotoProduk}
                  onChange={handleChange('fotoProduk')}
                  width="100%"
                  placeholder="https://bosindo.com/pic1.png"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Detail Produk
              </Text>
            </Flex>
          </CardHeader>

          <CardBody overflowX={{ sm: 'scroll', xl: 'hidden' }}>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Keterangan
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Textarea
                  disabled={isLoading}
                  value={values.keterangan}
                  onChange={handleChange('keterangan')}
                  width="100%"
                  minHeight="240px"
                  placeholder={`Memberikan layanan server Plesk

Fitur:
- 4 CPU
- 25GB Storage
- Unlimited Database
- Unlimited Bandwidth
- 100% Uptime
- Antivirus

Masa aktif layanan:
30 Hari

Cara pesan:
- Lakukan checkout
- Hubungi tim kami melalui halaman Pesanan
`}
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Stok Produk
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.stok}
                  onChange={handleChange('stok')}
                  width="100%"
                  placeholder="10"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Pengaturan Layanan Normal
              </Text>
            </Flex>
          </CardHeader>

          <CardBody overflowX={{ sm: 'scroll', xl: 'hidden' }}>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Durasi Normal
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.durasiNormal}
                  onChange={handleChange('durasiNormal')}
                  width="100%"
                  placeholder="14"
                />
                <Text fontSize="sm" mt={2} color={secondaryTextColor}>
                  * Durasi dalam hari
                </Text>
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Harga Normal
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.hargaNormal}
                  onChange={handleChange('hargaNormal')}
                  width="100%"
                  placeholder="15000"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Text fontSize="md" as="b" mb={2}>
                  Diskon Harga Normal
                </Text>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.diskonNormal}
                  onChange={handleChange('diskonNormal')}
                  width="100%"
                  placeholder="25"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Pengaturan Layanan Berlangganan
              </Text>
            </Flex>
          </CardHeader>

          <CardBody overflowX={{ sm: 'scroll', xl: 'hidden' }}>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Durasi Berlangganan
                  </Text>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.durasiBerlangganan}
                  onChange={handleChange('durasiBerlangganan')}
                  width="100%"
                  placeholder="30"
                />
                <Text fontSize="sm" mt={2} color={secondaryTextColor}>
                  * Durasi dalam hari
                </Text>
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Text fontSize="md" as="b" mb={2}>
                  Harga Berlangganan
                </Text>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.hargaBerlangganan}
                  onChange={handleChange('hargaBerlangganan')}
                  width="100%"
                  placeholder="250000"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Text fontSize="md" as="b" mb={2}>
                  Diskon Harga Berlangganan
                </Text>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.diskonBerlangganan}
                  onChange={handleChange('diskonBerlangganan')}
                  width="100%"
                  placeholder="25"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Biaya Tambahan
              </Text>
            </Flex>
          </CardHeader>

          <CardBody overflowX={{ sm: 'scroll', xl: 'hidden' }}>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Jenis Pajak
                  </Text>
                </Flex>
                <RadioGroup value={values.jenisPajak}>
                  <HStack spacing={4}>
                    <Radio
                      value="Tidak Ada"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Tidak Ada',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Tidak Ada
                    </Radio>
                    <Radio
                      value="Persen"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Persen',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Persen
                    </Radio>
                    <Radio
                      value="Flat"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Flat',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Flat
                    </Radio>
                  </HStack>
                </RadioGroup>
              </Flex>

              {values.jenisPajak !== 'Tidak Ada' && (
                <div>
                  {values.jenisPajak === 'Persen' ? (
                    <Flex direction="column" p={1} mt={4} width="100%">
                      <Flex align="center" mb={2}>
                        <Text fontSize="md" as="b">
                          Pajak Persen
                        </Text>
                        <Badge ml="1" fontSize="0.8em">
                          Wajib
                        </Badge>
                      </Flex>
                      <Input
                        disabled={isLoading}
                        type="number"
                        value={values.pajakPersen}
                        onChange={handleChange('pajakPersen')}
                        width="100%"
                        placeholder="25"
                      />
                    </Flex>
                  ) : (
                    <Flex direction="column" p={1} mt={4} width="100%">
                      <Flex align="center" mb={2}>
                        <Text fontSize="md" as="b">
                          Pajak Flat
                        </Text>
                        <Badge ml="1" fontSize="0.8em">
                          Wajib
                        </Badge>
                      </Flex>
                      <Input
                        disabled={isLoading}
                        type="number"
                        value={values.pajakFlat}
                        onChange={handleChange('pajakFlat')}
                        width="100%"
                        placeholder="2500"
                      />
                    </Flex>
                  )}
                </div>
              )}
            </Flex>
          </CardBody>
        </Card>

        <HStack sx={{ px: 4, ml: 'auto', mt: 5 }} spacing={4}>
          <NavLink to="/admin/daftar-layanan">
            <Button disabled={isLoading}>Batalkan</Button>
          </NavLink>
          <Button
            disabled={isLoading}
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </HStack>
      </Flex>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default TambahLayanan;
