import React, { useState } from 'react';
import {
  Text,
  useColorModeValue,
  Flex,
  Input,
  Button,
  HStack,
  Badge,
  Select,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { userAPI } from 'utils/userAPI';
import { useAuth } from 'hooks/useAuth';

const TambahPengguna = () => {
  const { user } = useAuth();
  const textColor = useColorModeValue('gray.700', 'white');

  const [values, setValues] = useState({
    name: '',
    email: '',
    role: 'Admin',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleRoleChange = (e) => {
    setValues({ ...values, role: e.target.value });
  };

  const handleSave = async () => {
    const { name, email, password } = values;

    if (!name)
      return toast.warn('Nama Pengguna wajib diisi', { toastId: 'warn-input' });

    if (name.trim().length < 2)
      return toast.warn('Nama Pengguna minimal 2 karakter', {
        toastId: 'warn-input',
      });

    if (!email)
      return toast.warn('Email wajib diisi', { toastId: 'warn-input' });

    if (!password)
      return toast.warn('Kata Sandi wajib diisi', { toastId: 'warn-input' });

    if (password.trim().length < 8)
      return toast.warn('Password minimal 8 karakter', {
        toastId: 'warn-input',
      });

    toast.dismiss();

    setIsLoading(true);

    const api = new userAPI();
    const [error] = await api.create(
      {
        displayName: values.name,
        email: values.email,
        role: values.role,
        password: values.password,
      },
      user.token
    );

    setIsLoading(false);

    setValues({
      name: '',
      email: '',
      role: 'Admin',
      password: '',
    });

    if (error)
      return toast.error('Gagal menambahkan pengguna', {
        toastId: 'toast-result',
      });

    return toast.success('Berhasil menambahkan pengguna', {
      toastId: 'toast-result',
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Pengguna
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.name}
                  onChange={handleChange('name')}
                  width="100%"
                  placeholder="Sabda Maulana"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Email
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.email}
                  onChange={handleChange('email')}
                  width="100%"
                  placeholder="sabda@raharja.info"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Kata Sandi
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.password}
                  onChange={handleChange('password')}
                  width="100%"
                  type="password"
                  placeholder="kata sandi"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Role
                  </Text>
                </Flex>
                <Select value={values.role} onChange={handleRoleChange}>
                  <option value="Admin">Admin</option>
                  <option value="Finance">Finance</option>
                </Select>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <HStack sx={{ px: 4, ml: 'auto', mt: 5 }} spacing={4}>
          <NavLink to="/admin/pengguna-sistem">
            <Button disabled={isLoading}>Batalkan</Button>
          </NavLink>
          <Button
            disabled={isLoading}
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </HStack>
      </Flex>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default TambahPengguna;
