import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Input,
  Select,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';

import { userAPI } from 'utils/userAPI';

const EditUserModal = ({ isOpen, onClose, data, token }) => {
  const [values, setValues] = useState({
    id: '',
    emailPengguna: '',
    namaPengguna: '',
    status: '',
    role: '',
  });

  const handleSubmit = async () => {
    if (values.status === data.status && values.role === data.role) return;

    const API = new userAPI();

    const [error] = await API.updateRoleStatus(
      values.id,
      { status: values.status, role: values.role },
      token
    );

    if (error) {
      return toast.error('Gagal mengubah data pengguna', {
        toastId: 'toast-result',
      });
    }

    return toast.success('Berhasil mengubah data pengguna', {
      toastId: 'toast-result',
    });
  };

  const handleStatusChange = (e) => {
    setValues({ ...values, status: e.target.value });
  };

  const handleRoleChange = (e) => {
    setValues({ ...values, role: e.target.value });
  };

  useEffect(() => {
    if (data.emailPengguna) {
      setValues({
        id: data.id,
        emailPengguna: data.emailPengguna,
        namaPengguna: data.namaPengguna,
        status: data.status,
        role: data.role,
      });
    }
  }, [data]);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Pengguna</ModalHeader>
          <ModalCloseButton />

          {data.emailPengguna && (
            <ModalBody>
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Email Pengguna
                  </Text>
                </Flex>
                <Input value={data.emailPengguna} width="100%" />
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama Pengguna
                  </Text>
                </Flex>
                <Input value={data.namaPengguna} width="100%" />
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Status
                  </Text>
                </Flex>
                <Select value={values.status} onChange={handleStatusChange}>
                  <option value="Aktif">Aktif</option>
                  <option value="Tidak Aktif">Tidak Aktif</option>
                </Select>
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Role
                  </Text>
                </Flex>
                <Select value={values.role} onChange={handleRoleChange}>
                  <option value="Admin">Admin</option>
                  <option value="Finance">Finance</option>
                  <option value="User">User</option>
                </Select>
              </Flex>
            </ModalBody>
          )}

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Batalkan
            </Button>
            <Button
              bgColor="green.400"
              color="white"
              _hover={{ bgColor: 'green.500' }}
              _active={{ bgColor: 'green.600' }}
              onClick={handleSubmit}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default EditUserModal;
