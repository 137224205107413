import axios from 'axios';
import { serverURL } from '../config/config';

const server = `${serverURL}/api/v1/payment`;

const APIKey = 'kodok';
const API = `${server}?key=${APIKey}`;

class midtransAPI {
  async create(parameter, transaction) {
    try {
      const res = await axios.post(API, {
        parameter,
        transaction,
      });

      return [null, res.data];
    } catch (error) {
      return [error, null];
    }
  }
}

export { midtransAPI };
