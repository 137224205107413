import {
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import InvoiceRow from 'components/Tables/InvoiceRow';
import React from 'react';
import { formatCurrency } from 'utils/formatCurrency';

const InvoiceTable = ({ captions, data }) => {
  const textColor = useColorModeValue('gray.700', 'white');

  const calculatePrice = (price, discount) => {
    return discount ? price - (price * discount) / 100 : price;
  };

  return (
    <>
      <Card>
        <CardBody overflowX={{ sm: 'auto', xl: 'auto' }}>
          <Table variant="simple" color={textColor} whiteSpace="nowrap">
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => {
                  return (
                    <Th
                      color={textColor}
                      key={idx}
                      ps={idx === 0 ? '0px' : null}
                      textAlign={idx === captions.length - 1 ? 'right' : 'left'}
                      pe={idx === captions.length - 1 ? '0px' : null}
                    >
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                return (
                  <InvoiceRow
                    key={row.id + index}
                    name={row.name}
                    variant={row.variant}
                    price={formatCurrency(row.price)}
                    discount={row.discount}
                    total={formatCurrency(
                      calculatePrice(row.price, row.discount)
                    )}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default InvoiceTable;
