import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

const UserContext = createContext();

const ProvideUser = ({ children }) => {
  const userProvider = useProvideUser();

  return (
    <UserContext.Provider value={userProvider}>{children}</UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

const useProvideUser = () => {
  const [data, setData] = useState('loading');

  const firestore = getFirestore();

  useEffect(() => {
    const docsRef = collection(firestore, 'users');

    const unsubscribe = onSnapshot(docsRef, (snapshot) => {
      if (snapshot.empty) return setData([]);

      const userData = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setData(userData);
    });

    return () => unsubscribe();
  }, []);

  return { data };
};

export { ProvideUser, useUser };
