import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatCurrency } from 'utils/formatCurrency';
import { formatDate } from 'utils/formatDate';

const ChartPenjualan = ({ data }) => {
  if (!data) return null;

  const values = data.map((row) => row.sum);
  const months = data.map((row) => formatDate(row.timestamp, 'MMMM'));

  const chartData = [
    {
      name: 'Penjualan',
      data: values,
    },
  ];

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: '#c8cfca',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#c8cfca',
          fontSize: '12px',
        },
        formatter: (value) => {
          return formatCurrency(value);
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ['#4FD1C5'],
    },
    colors: ['#4FD1C5'],
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="area"
      width="100%"
      height="100%"
    />
  );
};

export default ChartPenjualan;
