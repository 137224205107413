import React, { useState } from 'react';
import {
  Text,
  useColorModeValue,
  Flex,
  Input,
  Button,
  HStack,
  Radio,
  RadioGroup,
  Badge,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import currency from 'currency.js';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { midtransAPI } from 'utils/midtransAPI';
import { endOfDay, startOfDay } from 'date-fns';

const TambahTransaksi = () => {
  const textColor = useColorModeValue('gray.700', 'white');

  const [values, setValues] = useState({
    namaPembeli: '',
    emailPembeli: '',
    namaProduk: '',
    harga: '',
    diskon: '',
    jenisPajak: 'Tidak Ada',
    pajakFlat: '',
    pajakPersen: '',
    tanggalInvoice: '',
    tanggalJatuhTempo: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSave = async () => {
    const {
      namaPembeli,
      emailPembeli,
      namaProduk,
      harga,
      diskon,
      jenisPajak,
      pajakFlat,
      pajakPersen,
      tanggalInvoice,
      tanggalJatuhTempo,
    } = values;

    if (!namaPembeli)
      return toast.warn('Nama Pembeli wajib diisi', { toastId: 'warn-input' });

    if (namaPembeli.trim().length < 2)
      return toast.warn('Nama Pembeli minimal 2 karakter', {
        toastId: 'warn-input',
      });

    if (!emailPembeli)
      return toast.warn('Email wajib diisi', { toastId: 'warn-input' });

    if (emailPembeli.trim().length < 6)
      return toast.warn('Email minimal 6 karakter', {
        toastId: 'warn-input',
      });

    if (!namaProduk)
      return toast.warn('Nama Produk wajib diisi', { toastId: 'warn-input' });

    if (namaProduk.trim().length < 6)
      return toast.warn('Nama Produk minimal 6 karakter', {
        toastId: 'warn-input',
      });

    if (!harga)
      return toast.warn('Harga wajib diisi', { toastId: 'warn-input' });

    if (harga < 1000)
      return toast.warn('Harga minimal 1000', { toastId: 'warn-input' });

    if (jenisPajak === 'Persen' && !pajakPersen)
      return toast.warn('Pajak wajib diisi', { toastId: 'warn-input' });

    if (jenisPajak === 'Persen' && pajakPersen <= 0)
      return toast.warn('Pajak minimal 1', { toastId: 'warn-input' });

    if (jenisPajak === 'Persen' && pajakPersen > 99)
      return toast.warn('Pajak maksimal 99', { toastId: 'warn-input' });

    if (jenisPajak === 'Flat' && !pajakFlat)
      return toast.warn('Pajak wajib diisi', { toastId: 'warn-input' });

    if (jenisPajak === 'Flat' && pajakFlat < 0)
      return toast.warn('Pajak tidak boleh kurang dari 0', {
        toastId: 'warn-input',
      });

    if (!tanggalInvoice)
      return toast.warn('Tanggal invoice wajib diisi', {
        toastId: 'warn-input',
      });

    if (!tanggalJatuhTempo)
      return toast.warn('Tanggal jatuh tempo wajib diisi', {
        toastId: 'warn-input',
      });

    const timestampInvoice = new Date(tanggalInvoice).getTime();
    const timestampJatuhTempo = new Date(tanggalJatuhTempo).getTime();

    if (timestampJatuhTempo < timestampInvoice)
      return toast.warn('Tanggal jatuh tempo harus setelah tanggal invoice', {
        toastId: 'warn-input',
      });

    toast.dismiss();

    setIsLoading(true);

    const discount = diskon && harga * (diskon / 100);
    const totalPrice = discount ? harga - discount : harga;

    let ppn = 0;
    let tipePajak = 'Tidak Ada';
    let totalPajak = 0;

    if (jenisPajak === 'Tidak Ada') {
      ppn = 0;
      tipePajak = 'Flat';
      totalPajak = Number(0);
    }

    if (jenisPajak === 'Persen') {
      ppn = (totalPrice * pajakPersen) / 100;
      tipePajak = 'Persen';
      totalPajak = Number(pajakPersen);
    }

    if (jenisPajak === 'Flat') {
      ppn = pajakFlat;
      tipePajak = 'Flat';
      totalPajak = Number(pajakFlat);
    }

    const item_details = discount
      ? [
          {
            id: 'product',
            price: currency(harga, { precision: 0 }),
            quantity: 1,
            name: namaProduk,
          },
          {
            id: `discount`,
            price: currency(-discount, { precision: 0 }),
            quantity: 1,
            name: 'Potongan Harga',
          },
          {
            id: 'ppn',
            price: currency(ppn, { precision: 0 }),
            quantity: 1,
            name: 'PPN',
          },
        ]
      : [
          {
            id: 'product',
            price: currency(harga, { precision: 0 }),
            quantity: 1,
            name: namaProduk,
          },
          {
            id: 'ppn',
            price: currency(ppn, { precision: 0 }),
            quantity: 1,
            name: 'PPN',
          },
        ];

    const parameter = {
      transaction_details: {
        order_id: '',
        gross_amount: currency(totalPrice, { precision: 0 }).add(ppn),
      },
      item_details,
      customer_details: {
        first_name: namaPembeli,
        email: emailPembeli,
      },
      enabled_payments: 'bank_transfer',
    };

    const transaction = {
      product: {
        id: 'product',
        name: namaProduk,
        price: currency(harga, { precision: 0 }),
        discount: diskon ? Number(diskon) : null,
        variant: 'Normal',
        duration: 336,
        image: '',
      },
      user: {
        id: 'user',
        name: namaPembeli,
        email: emailPembeli,
      },
      payment: {
        name: 'Transfer Bank',
        link: '',
        status: 'Menunggu Pembayaran',
        createdAt: startOfDay(timestampInvoice).getTime(),
        expiredAt: endOfDay(timestampJatuhTempo).getTime(),
        ppn: {
          type: tipePajak,
          value: totalPajak,
        },
      },
    };

    const api = new midtransAPI();
    const [error] = await api.create(parameter, transaction);

    setIsLoading(false);

    setValues({
      namaPembeli: '',
      emailPembeli: '',
      namaProduk: '',
      harga: '',
      diskon: '',
      jenisPajak: 'Persen',
      pajakFlat: '',
      pajakPersen: '',
      tanggalInvoice: '',
      tanggalJatuhTempo: '',
    });

    if (error)
      return toast.error('Gagal menambahkan transaksi', {
        toastId: 'toast-result',
      });

    return toast.success('Berhasil menambahkan transaksi', {
      toastId: 'toast-result',
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Pembeli
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.namaPembeli}
                  onChange={handleChange('namaPembeli')}
                  width="100%"
                  placeholder="Sabda Maulana"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Email
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.emailPembeli}
                  onChange={handleChange('emailPembeli')}
                  width="100%"
                  placeholder="sabda@raharja.info"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Produk
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama Produk
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  value={values.namaProduk}
                  onChange={handleChange('namaProduk')}
                  width="100%"
                  placeholder="Paket Layanan Server Plesk"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Harga
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.harga}
                  onChange={handleChange('harga')}
                  width="100%"
                  placeholder="150000"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Text fontSize="md" as="b" mb={2}>
                  Diskon
                </Text>
                <Input
                  disabled={isLoading}
                  type="number"
                  value={values.diskon}
                  onChange={handleChange('diskon')}
                  width="100%"
                  placeholder="25"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Jenis Pajak
                  </Text>
                </Flex>
                <RadioGroup value={values.jenisPajak}>
                  <HStack spacing={4}>
                    <Radio
                      value="Tidak Ada"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Tidak Ada',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Tidak Ada
                    </Radio>
                    <Radio
                      value="Persen"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Persen',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Persen
                    </Radio>
                    <Radio
                      value="Flat"
                      onChange={() => {
                        setValues({
                          ...values,
                          jenisPajak: 'Flat',
                          pajakFlat: '',
                          pajakPersen: '',
                        });
                      }}
                    >
                      Flat
                    </Radio>
                  </HStack>
                </RadioGroup>
              </Flex>

              {values.jenisPajak !== 'Tidak Ada' && (
                <div>
                  {values.jenisPajak === 'Persen' ? (
                    <Flex direction="column" p={1} mt={4} width="100%">
                      <Flex align="center" mb={2}>
                        <Text fontSize="md" as="b">
                          Pajak Persen
                        </Text>
                        <Badge ml="1" fontSize="0.8em">
                          Wajib
                        </Badge>
                      </Flex>
                      <Input
                        disabled={isLoading}
                        type="number"
                        value={values.pajakPersen}
                        onChange={handleChange('pajakPersen')}
                        width="100%"
                        placeholder="25"
                      />
                    </Flex>
                  ) : (
                    <Flex direction="column" p={1} mt={4} width="100%">
                      <Flex align="center" mb={2}>
                        <Text fontSize="md" as="b">
                          Pajak Flat
                        </Text>
                        <Badge ml="1" fontSize="0.8em">
                          Wajib
                        </Badge>
                      </Flex>
                      <Input
                        disabled={isLoading}
                        type="number"
                        value={values.pajakFlat}
                        onChange={handleChange('pajakFlat')}
                        width="100%"
                        placeholder="2500"
                      />
                    </Flex>
                  )}
                </div>
              )}
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Flex
              justifyContent="space-between"
              direction={{ sm: 'column', md: 'row' }}
              w="100%"
            >
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Informasi Pembayaran
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Flex direction="column" width="100%">
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Tanggal Invoice
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  type="date"
                  disabled={isLoading}
                  value={values.tanggalInvoice}
                  onChange={handleChange('tanggalInvoice')}
                  width="100%"
                />
              </Flex>

              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Tanggal Jatuh Tempo
                  </Text>
                  <Badge ml="1" fontSize="0.8em">
                    Wajib
                  </Badge>
                </Flex>
                <Input
                  type="date"
                  disabled={isLoading}
                  value={values.tanggalJatuhTempo}
                  onChange={handleChange('tanggalJatuhTempo')}
                  width="100%"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <HStack sx={{ px: 4, ml: 'auto', mt: 5 }} spacing={4}>
          <NavLink to="/admin/daftar-transaksi">
            <Button disabled={isLoading}>Batalkan</Button>
          </NavLink>
          <Button
            disabled={isLoading}
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </HStack>
      </Flex>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default TambahTransaksi;
