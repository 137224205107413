import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FiChevronDown, FiEdit, FiTrash } from 'react-icons/fi';

const DaftarPenggunaAction = (props) => {
  const { onEdit, onDelete } = props;

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon as={FiChevronDown} color="inherit" />}
      >
        Atur
      </MenuButton>
      <MenuList minWidth="160px">
        <MenuItem onClick={onEdit} py={3} px={4} icon={<Icon as={FiEdit} />}>
          Ubah
        </MenuItem>
        <MenuItem onClick={onDelete} py={3} px={4} icon={<Icon as={FiTrash} />}>
          Hapus
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DaftarPenggunaAction;
