import { Flex, Button, HStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';
import { FiPlus } from 'react-icons/fi';

import Authors from './components/DaftarPenggunaTable';
import { useUser } from 'hooks/useUser';
import { createPagination } from 'utils/createPagination';

const PenggunaSistem = () => {
  const user = useUser();

  const [userData, setUserData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => () => {
    if (page === '...') return;
    setCurrentPage(page);
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    if (user.data === 'loading') return setUserData([]);

    const newUserData = user.data
      .map((row) => {
        return {
          id: row.id,
          name: row.name,
          avatar: row.avatar,
          email: row.email,
          role: row.role ? row.role : 'User',
          status: row.inActive ? 'Tidak Aktif' : 'Aktif',
        };
      })
      .filter((row) => {
        if (searchKeyword.trim().length < 3 && row.role !== 'User') return true;

        return row.email.indexOf(searchKeyword) > -1 && row.role !== 'User';
      });

    setCurrentPage(1);
    setNumPages(Math.ceil(newUserData.length / 10));
    setUserData(newUserData);
  }, [searchKeyword, user.data]);

  const pagination = createPagination(currentPage, numPages);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <HStack sx={{ px: 4, mt: 5 }} spacing={4}>
        <NavLink to="/admin/pengguna-sistem/tambah">
          <Button
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            leftIcon={<Icon as={FiPlus} color="inherit" />}
          >
            Tambah Pengguna
          </Button>
        </NavLink>
      </HStack>

      <Authors
        title={'Pengguna Sistem'}
        captions={['Nama', 'Email', 'Role', 'Status', 'Aksi']}
        data={userData.slice(
          (currentPage - 1) * 10,
          (currentPage - 1) * 10 + 10
        )}
        onSearch={handleSearch}
      />

      <HStack sx={{ ml: 'auto', px: 5 }} spacing={2}>
        {pagination.map((page, index) => {
          return (
            <Button key={page + index} onClick={handlePageChange(page)}>
              {page}
            </Button>
          );
        })}
      </HStack>
    </Flex>
  );
};

export default PenggunaSistem;
