import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FiChevronDown, FiEdit, FiExternalLink } from 'react-icons/fi';

const DaftarTransaksiAction = ({ onEdit, onInvoice }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon as={FiChevronDown} color="inherit" />}
      >
        Atur
      </MenuButton>
      <MenuList minWidth="160px">
        <MenuItem onClick={onEdit} py={3} px={4} icon={<Icon as={FiEdit} />}>
          Ubah
        </MenuItem>
        <MenuItem
          onClick={onInvoice}
          py={3}
          px={4}
          icon={<Icon as={FiExternalLink} />}
        >
          Lihat Invoice
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DaftarTransaksiAction;
