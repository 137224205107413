import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Input,
  Select,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { isAfter } from 'date-fns';

import { transactionAPI } from 'utils/transactionAPI';
import { formatDate } from 'utils/formatDate';

const EditTransaksiModal = ({ isOpen, onClose, data }) => {
  const [values, setValues] = useState({
    status: '',
    date: 0,
  });

  const handleSubmit = async () => {
    if (values.status === data.status && values.date === data.expiredAt) return;

    const api = new transactionAPI();

    const currentTimestamp = Date.now();
    const onGoingStatus = [
      'Sedang Diproses',
      'Menunggu Pembayaran',
      'Transaksi Kadaluarsa',
    ];

    let newStatus = values.status;
    if (onGoingStatus.includes(values.status)) {
      if (isAfter(values.date, currentTimestamp)) {
        newStatus = 'Menunggu Pembayaran';
      } else {
        newStatus = 'Transaksi Kadaluarsa';
      }
    }

    const [error] = await api.update(data.idTransaksi, newStatus, values.date);

    if (error)
      return toast.error('Gagal mengubah status', { toastId: 'toast-result' });

    return toast.success('Berhasil mengubah status', {
      toastId: 'toast-result',
    });
  };

  const handleStatusChange = (e) => {
    setValues({ ...values, status: e.target.value });
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value).getTime();
    setValues({ ...values, date });
  };

  useEffect(() => {
    if (data.status) {
      setValues({ status: data.status, date: data.expiredAt });
    }
  }, [data]);

  const currentTimestamp = Date.now();

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Transaksi</ModalHeader>
          <ModalCloseButton />

          {data.status && (
            <ModalBody>
              <Flex direction="column" p={1} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    ID Transaksi
                  </Text>
                </Flex>
                <Input value={data.idTransaksi} width="100%" />
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Nama Layanan
                  </Text>
                </Flex>
                <Input value={data.namaLayanan} width="100%" />
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Jatuh Tempo
                  </Text>
                </Flex>
                <Input
                  value={formatDate(values.date, 'yyyy-MM-dd')}
                  width="100%"
                  type="date"
                  onChange={handleDateChange}
                />
              </Flex>
              <Flex direction="column" p={1} mt={4} width="100%">
                <Flex align="center" mb={2}>
                  <Text fontSize="md" as="b">
                    Status
                  </Text>
                </Flex>
                <Select value={values.status} onChange={handleStatusChange}>
                  {isAfter(values.date, currentTimestamp) ? (
                    <option value="Menunggu Pembayaran">
                      Menunggu Pembayaran
                    </option>
                  ) : (
                    <option value="Transaksi Kadaluarsa">
                      Transaksi Kadaluarsa
                    </option>
                  )}

                  <option value="Transaksi Berhasil">Transaksi Berhasil</option>
                  <option value="Transaksi Gagal">Transaksi Gagal</option>
                </Select>
              </Flex>
            </ModalBody>
          )}

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Batalkan
            </Button>
            <Button
              bgColor="green.400"
              color="white"
              _hover={{ bgColor: 'green.500' }}
              _active={{ bgColor: 'green.600' }}
              onClick={handleSubmit}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default EditTransaksiModal;
