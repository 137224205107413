import currency from 'currency.js';

const formatCurrency = (value = 0) => {
  return currency(value, {
    symbol: 'Rp',
    separator: '.',
    decimal: ',',
    precision: 0,
  }).format();
};

export { formatCurrency };
