import { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Flex,
  InputGroup,
  IconButton,
  InputLeftElement,
  Input,
  useDisclosure,
} from '@chakra-ui/react';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import DaftarTransaksiRow from 'components/Tables/DaftarTransaksiRow';
import React from 'react';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import EditTransaksiModal from 'components/Modal/EditTransaksiModal';

const DaftarTransaksiTable = ({ title, captions, data, onSearch }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [modalData, setModalData] = useState({
    namaLayanan: '',
    idTransaksi: '',
    status: '',
  });

  const textColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('white', 'gray.800');
  const mainTeal = useColorModeValue('teal.300', 'teal.300');
  const mainText = useColorModeValue('gray.700', 'gray.200');
  const searchIcon = useColorModeValue('gray.700', 'gray.200');

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const handleModalEditClose = () => {
    setModalData({ namaLayanan: '', idTransaksi: '', status: '' });
    onClose();
  };

  const handleEdit = (index) => () => {
    const newModalData = {
      namaLayanan: data[index].name,
      idTransaksi: data[index].id,
      status:
        data[index].status === 'cancel'
          ? 'Transaksi Gagal'
          : data[index].status,
      expiredAt: data[index].expiredAt,
    };

    setModalData(newModalData);
    onOpen();
  };

  const handleInvoice = (id) => () => {
    window.open(
      `${window.location.protocol}//${window.location.host}/#/print/invoice/${id}`
    );
  };

  return (
    <>
      <Card>
        <CardHeader p="6px 0px 22px 0px">
          <Flex
            justifyContent="space-between"
            direction={{ sm: 'column', md: 'row' }}
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {title}
            </Text>
            <InputGroup
              cursor="pointer"
              bg={inputBg}
              borderRadius="15px"
              mt={{ sm: '8px' }}
              w={{
                sm: '100%',
                md: '200px',
              }}
              _focus={{
                borderColor: { mainTeal },
              }}
              _active={{
                borderColor: { mainTeal },
              }}
            >
              <InputLeftElement>
                <IconButton
                  bg="inherit"
                  borderRadius="inherit"
                  _hover={{}}
                  _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                ></IconButton>
              </InputLeftElement>
              <Input
                onChange={handleSearchChange}
                fontSize="xs"
                py="11px"
                color={mainText}
                placeholder="Cari ID transaksi..."
                borderRadius="inherit"
              />
            </InputGroup>
          </Flex>
        </CardHeader>
        <CardBody overflowX={{ sm: 'auto', xl: 'auto' }}>
          <Table variant="simple" color={textColor} whiteSpace="nowrap">
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? '0px' : null}
                    >
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                return (
                  <DaftarTransaksiRow
                    key={row.id + index}
                    id={row.id}
                    name={row.name}
                    image={row.image}
                    price={formatCurrency(row.price)}
                    variant={row.variant}
                    status={
                      row.status === 'cancel' ? 'Transaksi Gagal' : row.status
                    }
                    date={formatDate(row.date, 'dd MMMM yyyy')}
                    onEdit={handleEdit(index)}
                    onInvoice={handleInvoice(row.id)}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      <EditTransaksiModal
        isOpen={isOpen}
        onClose={handleModalEditClose}
        data={modalData}
      />
    </>
  );
};

export default DaftarTransaksiTable;
