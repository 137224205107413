/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBl51XnzDjl2vRnC2xezNJyy6W6l6h8oEA',
  authDomain: 'bigboyz-app.firebaseapp.com',
  projectId: 'bigboyz-app',
  storageBucket: 'bigboyz-app.appspot.com',
  messagingSenderId: '301803574936',
  appId: '1:301803574936:web:f2de25c65f2daf4ba9e2ed',
  measurementId: 'G-HTNREMCPXC',
};

initializeApp(firebaseConfig);

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import PrintLayout from 'layouts/Print';
import { ProvideAuth } from 'hooks/useAuth';
import Invoice from 'views/Invoice/Invoice';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ProvideAuth>
    <HashRouter>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Route path={`/print`} component={PrintLayout} />
        <Route path={`/get/invoice/:id`} component={Invoice} />
        <Redirect from={`/`} to="/admin/dashboard" />
      </Switch>
    </HashRouter>
  </ProvideAuth>
);
