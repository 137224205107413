import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { userAPI } from 'utils/userAPI';
import { capitalize } from 'utils/formatString';

const AuthContext = createContext();

const ProvideAuth = ({ children }) => {
  const authProvider = useProvideAuth();

  if (authProvider.user === 'loading') return null;

  return (
    <AuthContext.Provider value={authProvider}>{children}</AuthContext.Provider>
  );
};

const API = new userAPI();
const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState('loading');

  const auth = getAuth();
  const roles = ['admin', 'finance'];

  const loginSSO = async () => {
    try {
      const provider = new GoogleAuthProvider();

      const res = await signInWithPopup(auth, provider);
      const token = await res.user.getIdTokenResult();

      const role = token.claims.role;

      if (!role) return [null, false];
      if (!roles.includes(role)) return [null, false];

      const [error] = await API.update(res.user.uid, {
        name: res.user.displayName,
        avatar: res.user.photoURL,
        role: capitalize(role) || 'User',
      });

      if (error) {
        API.set(res.user.uid, {
          email: res.user.email,
          name: res.user.displayName,
          avatar: res.user.photoURL,
          role: capitalize(role) || 'User',
        });
      }

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  };

  const login = async (email, password) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const token = await res.user.getIdTokenResult();

      const role = token.claims.role;

      if (!role) return [null, false];
      if (!roles.includes(role)) return [null, false];

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authState) => {
      if (!authState) return setUser(null);

      authState.getIdTokenResult().then((tokenResult) => {
        const role = tokenResult.claims.role;

        if (!role) return logout();
        if (!roles.includes(role)) return logout();

        setUser({
          displayName: authState.displayName,
          email: authState.email,
          photoURL: authState.photoURL,
          role,
          token: tokenResult.token,
        });
      });
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  return { user, login, loginSSO, logout };
};

export { ProvideAuth, useAuth };
