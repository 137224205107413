import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar';
import routes from 'routes.js';
import theme from 'theme/theme.js';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import { useAuth } from 'hooks/useAuth';
import { ProvideTransaction } from 'hooks/useTransaction';
import { ProvideProduct } from 'hooks/useProduct';
import { ProvideOrder } from 'hooks/useOrder';
import { ProvideUser } from 'hooks/useUser';

export default function Dashboard(props) {
  const { ...rest } = props;

  const history = useHistory();
  const auth = useAuth();

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (auth.user === 'loading') return null;
      if (!auth.user) return null;

      if (prop.layout === '/auth') return null;

      if (prop.roles) {
        if (!prop.roles.includes(auth.user.role)) return null;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === 'admin') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const { onOpen } = useDisclosure();
  document.documentElement.dir = 'ltr';

  useEffect(() => {
    if (auth.user) return;
    history.replace('/auth/masuk');
  }, [auth.user]);

  if (auth.user === 'loading') return null;
  if (!auth.user) return null;

  return (
    <ProvideTransaction>
      <ProvideOrder>
        <ProvideProduct>
          <ProvideUser>
            <ChakraProvider theme={theme} resetCss={false}>
              <Sidebar
                routes={routes}
                logoText={'PURITY UI DASHBOARD'}
                display="none"
                sidebarVariant="transparent"
                {...rest}
              />
              <MainPanel
                w={{
                  base: '100%',
                  xl: 'calc(100% - 275px)',
                }}
              >
                <Portal>
                  <AdminNavbar
                    onOpen={onOpen}
                    logoText={'PURITY UI DASHBOARD'}
                    brandText={getActiveRoute(routes)}
                    secondary={getActiveNavbar(routes)}
                    fixed={false}
                    {...rest}
                  />
                </Portal>
                {getRoute() ? (
                  <PanelContent>
                    <PanelContainer>
                      <Switch>
                        {getRoutes(routes)}
                        <Redirect from="/admin" to="/admin/dashboard" />
                      </Switch>
                    </PanelContainer>
                  </PanelContent>
                ) : null}
              </MainPanel>
            </ChakraProvider>
          </ProvideUser>
        </ProvideProduct>
      </ProvideOrder>
    </ProvideTransaction>
  );
}
