import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FiChevronDown, FiEdit, FiTrash } from 'react-icons/fi';

const DaftarLayananAction = ({ onEdit, onDelete }) => {
  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon as={FiChevronDown} color="inherit" />}
      >
        Atur
      </MenuButton>
      <MenuList minWidth="160px">
        <MenuItem
          py={3}
          px={4}
          icon={<Icon as={FiEdit} />}
          onClick={handleEdit}
        >
          Ubah
        </MenuItem>
        <MenuItem
          py={3}
          px={4}
          icon={<Icon as={FiTrash} />}
          onClick={handleDelete}
        >
          Hapus
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DaftarLayananAction;
