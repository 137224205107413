import axios from 'axios';
import { useEffect } from 'react';

import { serverURL } from '../../config/config';

const Invoice = (props) => {
  const params = props.match.params;
  if (!params.id) return;

  useEffect(() => {
    const handleDownloadPDF = () => {
      axios
        .get(`${serverURL}/get/invoice/${params.id}`, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);

          window.open(pdfUrl, '_self');
        })
        .catch((e) => e);
    };

    handleDownloadPDF();
  }, [params.id]);

  return null;
};

export default Invoice;
