import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BgSignUp from 'assets/img/BgSignUp.png';
import { useAuth } from 'hooks/useAuth';

const Masuk = () => {
  const auth = useAuth();
  const bgColor = useColorModeValue('white', 'gray.700');

  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleLoginSSO = async () => {
    toast.dismiss();

    setIsLoading(true);
    const [error, status] = await auth.loginSSO();
    setIsLoading(false);

    if (error || !status)
      return toast.warn('Gagal masuk dengan Google', {
        toastId: 'warn-login',
      });
  };

  const handleSubmit = async () => {
    if (!values.email)
      return toast.warn('Email wajib diisi', { toastId: 'warn-input' });
    if (!values.password)
      return toast.warn('Kata Sandi wajib diisi', { toastId: 'warn-input' });

    toast.dismiss();

    setIsLoading(true);
    const [error, status] = await auth.login(values.email, values.password);
    setIsLoading(false);

    if (error || !status)
      return toast.warn('Email atau Kata Sandi salah', {
        toastId: 'warn-login',
      });
  };

  return (
    <>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Box
          position="absolute"
          minH={{ base: '70vh', md: '50vh' }}
          w={{ md: 'calc(100vw - 50px)' }}
          borderRadius={{ md: '15px' }}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          bgImage={BgSignUp}
          bgSize="cover"
          mx={{ md: 'auto' }}
          mt={{ md: '14px' }}
        ></Box>

        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: '16px' }}
            mt="32vh"
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Button
              disabled={isLoading}
              onClick={handleLoginSSO}
              type="submit"
              bg="teal.300"
              fontSize="14px"
              color="white"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              _hover={{
                bg: 'teal.200',
              }}
              _active={{
                bg: 'teal.400',
              }}
            >
              MASUK DENGAN GOOGLE
            </Button>
            <Text
              fontSize="lg"
              color="gray.400"
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Atau
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                disabled={isLoading}
                onChange={handleChange('email')}
                value={values.email}
                id="email"
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="email"
                mb="24px"
                size="lg"
              />

              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Kata Sandi
              </FormLabel>
              <Input
                disabled={isLoading}
                onChange={handleChange('password')}
                value={values.password}
                id="password"
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="password"
                mb="24px"
                size="lg"
              />

              <Button
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                bg="teal.300"
                fontSize="14px"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                _hover={{
                  bg: 'teal.200',
                }}
                _active={{
                  bg: 'teal.400',
                }}
              >
                MASUK
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>

      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Masuk;
