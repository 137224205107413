import {
  getFirestore,
  getDoc,
  query,
  doc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import axios from 'axios';
import { serverURL } from '../config/config';

const API = `${serverURL}/api/v1/user`;

class userAPI {
  async get(userId) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'users', userId));
      const res = await getDoc(docsRef);

      return [null, { id: res.id, ...res.data() }];
    } catch (error) {
      return [error, null];
    }
  }

  async set(userId, newData) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'users', userId));
      await setDoc(docsRef, newData);

      return [null, true];
    } catch (error) {
      return [null, error];
    }
  }

  async update(userId, newData) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'users', userId));
      await updateDoc(docsRef, newData);

      return [null, true];
    } catch (error) {
      return [null, error];
    }
  }

  async updateRoleStatus(userId, newData, token) {
    try {
      const res = await axios.post(
        `${API}/update`,
        { userId, newData },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      return [null, res.data];
    } catch (error) {
      return [error, null];
    }
  }

  async create(newData, token) {
    try {
      const res = await axios.post(
        `${API}/add`,
        { newData },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      return [null, res.data];
    } catch (error) {
      return [error, null];
    }
  }

  async remove(id, token) {
    try {
      const res = await axios.delete(`${API}/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      return [null, res.data];
    } catch (error) {
      return [error, null];
    }
  }
}

export { userAPI };
