import { getFirestore, setDoc, doc, getDoc } from 'firebase/firestore';

class invoiceAPI {
  async get(id) {
    try {
      const firestore = getFirestore();

      const docsRef = doc(firestore, 'invoices', id);
      const res = await getDoc(docsRef);

      return [null, { id: res.id, ...res.data() }];
    } catch (error) {
      return [error, null];
    }
  }

  // async update(id, data) {
  //   try {
  //     const firestore = getFirestore();

  //     const docsRef = query(doc(firestore, 'products', id));
  //     const res = await updateDoc(docsRef, { ...data, lastUpdate: Date.now() });

  //     return [null, res];
  //   } catch (error) {
  //     return [error, null];
  //   }
  // }

  async add(id, data) {
    try {
      const firestore = getFirestore();

      const docsRef = doc(firestore, 'invoices', id);
      const res = await setDoc(docsRef, data);

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }
}

export { invoiceAPI };
