import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { ProfileIcon } from 'components/Icons/Icons';
import SidebarResponsive from 'components/Sidebar/SidebarResponsive';
import routes from 'routes.js';
import { useAuth } from 'hooks/useAuth';

export default function HeaderLinks(props) {
  const { secondary, ...rest } = props;
  const auth = useAuth();

  const handleLogout = async () => {
    await auth.logout();
  };

  let navbarIcon = useColorModeValue('gray.500', 'gray.200');

  if (secondary) {
    navbarIcon = 'white';
  }

  return (
    <Flex
      pe={{ sm: '0px', md: '0px' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
    >
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />

      <Menu>
        <MenuButton ml="32px">
          <Button
            px="0px"
            me={{ sm: '2px', md: '16px' }}
            color={navbarIcon}
            variant="transparent-with-icon"
            rightIcon={
              document.documentElement.dir ? (
                ''
              ) : (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              ) : (
                ''
              )
            }
          >
            <Text>Admin</Text>
          </Button>
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" py={4} onClick={handleLogout}>
              <Text fontSize="xs" lineHeight="100%" color={navbarIcon}>
                Keluar
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
