import {
  getFirestore,
  addDoc,
  collection,
  query,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

class productAPI {
  async get(id) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'products', id));
      const res = await getDoc(docsRef);

      return [null, { id: res.id, ...res.data() }];
    } catch (error) {
      return [error, null];
    }
  }

  async update(id, data) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'products', id));
      const res = await updateDoc(docsRef, { ...data, lastUpdate: Date.now() });

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }

  async add(data) {
    try {
      const firestore = getFirestore();

      const docsRef = query(collection(firestore, 'products'));
      const res = await addDoc(docsRef, { ...data, lastUpdate: Date.now() });

      return [null, res.id];
    } catch (error) {
      return [error, null];
    }
  }

  async remove(id) {
    try {
      const firestore = getFirestore();

      const docsRef = query(doc(firestore, 'products', id));
      const res = await deleteDoc(docsRef);

      return [null, res];
    } catch (error) {
      return [error, null];
    }
  }
}

export { productAPI };
