import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Box, ChakraProvider } from '@chakra-ui/react';

import routes from 'routes.js';
import theme from 'theme/theme.js';
import { useAuth } from 'hooks/useAuth';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function Pages() {
  const history = useHistory();
  const auth = useAuth();

  const wrapper = React.createRef();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (auth.user === 'loading') return null;
      if (auth.user) return null;

      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === 'auth') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const navRef = React.useRef();
  document.documentElement.dir = 'ltr';

  useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() {};
  });

  useEffect(() => {
    if (!auth.user) return;
    history.replace('/admin/dashboard');
  }, [auth.user]);

  if (auth.user === 'loading') return null;
  if (auth.user) return null;

  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/auth" to="/auth/masuk" />
            </Switch>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
