import { Flex, Button, HStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';
import { FiPlus } from 'react-icons/fi';

import DaftarLayananTable from './components/DaftarLayananTable';
import { useProduct } from 'hooks/useProduct';
import { createPagination } from 'utils/createPagination';

const DaftarLayanan = () => {
  const product = useProduct();

  const [productData, setProductData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => () => {
    if (page === '...') return;
    setCurrentPage(page);
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    if (product.data === 'loading') return setProductData([]);

    const newProductData = product.data
      .map((row) => {
        return {
          id: row.id,
          name: row.name,
          image: row.image,
          normal: row.prices[0],
          subscribtion: row.prices[1],
          discountNormal: row.discounts[0],
          discountSubscribtion: row.discounts[1],
          category: row.category,
          inActive: row.status,
        };
      })
      .filter((row) => {
        if (searchKeyword.trim().length < 3) return true;
        return row.name.indexOf(searchKeyword) > -1;
      });

    setCurrentPage(1);
    setNumPages(Math.ceil(newProductData.length / 10));
    setProductData(newProductData);
  }, [searchKeyword, product.data]);

  const pagination = createPagination(currentPage, numPages);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <HStack sx={{ px: 4, mt: 5 }} spacing={4}>
        <NavLink to="/admin/daftar-layanan/tambah">
          <Button
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            leftIcon={<Icon as={FiPlus} color="inherit" />}
          >
            Tambah Layanan
          </Button>
        </NavLink>
      </HStack>

      <DaftarLayananTable
        title={'Daftar Layanan'}
        captions={[
          'Nama Layanan',
          'Harga Paket Normal',
          'Harga Paket Berlangganan',
          'Status',
          'Aksi',
        ]}
        data={productData.slice(
          (currentPage - 1) * 10,
          (currentPage - 1) * 10 + 10
        )}
        onSearch={handleSearch}
      />

      <HStack sx={{ ml: 'auto', px: 5 }} spacing={2}>
        {pagination.map((page, index) => {
          return (
            <Button key={page + index} onClick={handlePageChange(page)}>
              {page}
            </Button>
          );
        })}
      </HStack>
    </Flex>
  );
};

export default DaftarLayanan;
