import React from 'react';
import { Icon } from '@chakra-ui/icons';
import {
  FiCpu,
  FiCreditCard,
  FiHome,
  FiServer,
  FiMonitor,
  FiSmartphone,
} from 'react-icons/fi';

import TambahLayananAktif from 'views/Dashboard/TambahLayananAktif';
import LayananAktif from 'views/Dashboard/LayananAktif';
import TambahLayanan from 'views/Dashboard/TambahLayanan';
import DaftarLayanan from 'views/Dashboard/DaftarLayanan';
import TambahTransaksi from 'views/Dashboard/TambahTransaksi';
import DaftarTransaksi from 'views/Dashboard/DaftarTransaksi';
import DaftarPengguna from 'views/Dashboard/DaftarPengguna';
import Dashboard from 'views/Dashboard/Dashboard';
import Invoice from 'views/Dashboard/Invoice';
import Masuk from 'views/Auth/Masuk';
import UbahLayanan from 'views/Dashboard/UbahLayanan';
import PenggunaSistem from 'views/Dashboard/PenggunaSistem';
import TambahPengguna from 'views/Dashboard/TambahPengguna';

import { RocketIcon } from 'components/Icons/Icons';

const routes = [
  {
    name: 'Dashboard Admin',
    category: 'admin',
    sidebar: true,
    roles: ['admin', 'finance'],
    views: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        icon: <Icon as={FiHome} color="inherit" />,
        component: Dashboard,
        layout: '/admin',
        sidebar: true,
      },
    ],
  },
  {
    name: 'Layanan',
    category: 'admin',
    sidebar: true,
    roles: ['admin', 'finance'],
    views: [
      {
        path: '/layanan-aktif/tambah',
        name: 'Tambah Layanan Aktif',
        icon: <Icon as={FiCpu} color="inherit" />,
        component: TambahLayananAktif,
        layout: '/admin',
      },
      {
        path: '/layanan-aktif',
        name: 'Layanan Aktif',
        icon: <Icon as={FiCpu} color="inherit" />,
        component: LayananAktif,
        layout: '/admin',
        sidebar: true,
      },
      {
        path: '/daftar-layanan/ubah/:id',
        name: 'Ubah Layanan',
        icon: <Icon as={FiServer} color="inherit" />,
        component: UbahLayanan,
        layout: '/admin',
      },
      {
        path: '/daftar-layanan/tambah',
        name: 'Tambah Layanan',
        icon: <Icon as={FiServer} color="inherit" />,
        component: TambahLayanan,
        layout: '/admin',
      },
      {
        path: '/daftar-layanan',
        name: 'Daftar Layanan',
        icon: <Icon as={FiServer} color="inherit" />,
        component: DaftarLayanan,
        layout: '/admin',
        sidebar: true,
      },
    ],
  },
  {
    name: 'Transaksi',
    category: 'admin',
    sidebar: true,
    roles: ['admin', 'finance'],
    views: [
      {
        path: '/daftar-transaksi/tambah',
        name: 'Tambah Transaksi',
        icon: <Icon as={FiCreditCard} color="inherit" />,
        component: TambahTransaksi,
        layout: '/admin',
      },
      {
        path: '/daftar-transaksi',
        name: 'Daftar Transaksi',
        icon: <Icon as={FiCreditCard} color="inherit" />,
        component: DaftarTransaksi,
        layout: '/admin',
        sidebar: true,
      },
      {
        path: '/invoice',
        name: 'Invoice',
        icon: <Icon as={FiCreditCard} color="inherit" />,
        component: Invoice,
        layout: '/print',
      },
    ],
  },
  {
    name: 'Pengguna',
    category: 'admin',
    sidebar: true,
    roles: ['admin'],
    views: [
      {
        path: '/pengguna-aplikasi',
        name: 'Pengguna Aplikasi',
        icon: <Icon as={FiSmartphone} color="inherit" />,
        component: DaftarPengguna,
        layout: '/admin',
        sidebar: true,
      },
      {
        path: '/pengguna-sistem/tambah',
        name: 'Tambah Pengguna Sistem',
        icon: <Icon as={FiMonitor} color="inherit" />,
        component: TambahPengguna,
        layout: '/admin',
      },
      {
        path: '/pengguna-sistem',
        name: 'Pengguna Sistem',
        icon: <Icon as={FiMonitor} color="inherit" />,
        component: PenggunaSistem,
        layout: '/admin',
        sidebar: true,
      },
    ],
  },
  {
    name: 'Auth',
    category: 'auth',
    views: [
      {
        path: '/masuk',
        name: 'Masuk',
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: Masuk,
        layout: '/auth',
      },
    ],
  },
];

export default routes;
