import {
  Avatar,
  Badge,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

import DaftarTransaksiAction from 'components/Dropdowns/DaftarTransaksiAction';

const DaftarTransaksiRow = (props) => {
  const {
    image,
    id,
    name,
    price,
    variant,
    status,
    date,
    onEdit,
    onInvoice,
  } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', '#1a202c');
  const colorStatus = useColorModeValue('white', 'gray.400');

  const createBadge = (status) => {
    if (status === 'Transaksi Gagal')
      return (
        <Badge
          bg="red.400"
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      );

    if (status === 'Menunggu Pembayaran')
      return (
        <Badge
          bg="orange.400"
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      );

    if (status === 'Transaksi Berhasil')
      return (
        <Badge
          bg="green.400"
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      );

    return (
      <Badge
        bg={bgStatus}
        color={colorStatus}
        fontSize="16px"
        p="3px 10px"
        borderRadius="8px"
      >
        {status}
      </Badge>
    );
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar
            name={name}
            src={image}
            w="50px"
            borderRadius="12px"
            me="18px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {variant}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {id}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {price}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {date}
        </Text>
      </Td>
      <Td>{createBadge(status)}</Td>
      <Td>
        <HStack spacing="24px">
          <DaftarTransaksiAction onEdit={onEdit} onInvoice={onInvoice} />
        </HStack>
      </Td>
    </Tr>
  );
};

export default DaftarTransaksiRow;
