import { Flex, Button, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';
import { FiPlus } from 'react-icons/fi';

import DaftarTransaksiTable from './components/DaftarTransaksiTable';
import { useTransaction } from 'hooks/useTransaction';
import { createPagination } from 'utils/createPagination';

const calculatePrice = (price, discount) => {
  return discount ? price - (price * discount) / 100 : price;
};

const DaftarTransaksi = () => {
  const transaction = useTransaction();

  const [transactionData, setTransactionData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => () => {
    if (page === '...') return;
    setCurrentPage(page);
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    if (transaction.data === 'loading') return setTransactionData([]);

    const newTransactionData = transaction.data
      .map((row) => {
        return {
          id: row.id,
          name: row.product.name,
          image: row.product.image,
          price: calculatePrice(row.product.price, row.product.discount),
          variant:
            row.product.variant === 'Berlangganan' ? 'Berlangganan' : 'Normal',
          status: row.payment.status,
          date: row.payment.createdAt,
          expiredAt: row.payment.expiredAt,
          user: row.user,
          payment: row.payment,
          product: row.product,
        };
      })
      .filter((row) => {
        if (searchKeyword.trim().length < 3) return true;
        return row.id.indexOf(searchKeyword) > -1;
      })
      .sort((a, b) => b.id - a.id);

    setNumPages(Math.ceil(newTransactionData.length / 10));
    setTransactionData(newTransactionData);
  }, [searchKeyword, transaction.data]);

  useEffect(() => {
    if (currentPage > numPages) setCurrentPage(numPages);
  }, [numPages]);

  const pagination = createPagination(currentPage, numPages);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <HStack sx={{ px: 4, mt: 5 }} spacing={4}>
        <NavLink to="/admin/daftar-transaksi/tambah">
          <Button
            bgColor="green.400"
            color="white"
            _hover={{ bgColor: 'green.500' }}
            _active={{ bgColor: 'green.600' }}
            leftIcon={<Icon as={FiPlus} color="inherit" />}
          >
            Tambah Transaksi
          </Button>
        </NavLink>
      </HStack>

      <DaftarTransaksiTable
        title={'Daftar Transaksi'}
        captions={[
          'Nama Layanan',
          'ID Transaksi',
          'Harga',
          'Tanggal Transaksi',
          'Status',
          'Aksi',
        ]}
        data={transactionData.slice(
          (currentPage - 1) * 10,
          (currentPage - 1) * 10 + 10
        )}
        onSearch={handleSearch}
      />

      <HStack sx={{ ml: 'auto', px: 5 }} spacing={2}>
        {pagination.map((page, index) => {
          return (
            <Button key={page + index} onClick={handlePageChange(page)}>
              {page}
            </Button>
          );
        })}
      </HStack>
    </Flex>
  );
};

export default DaftarTransaksi;
