import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { isBefore } from 'date-fns';

const TransactionContext = createContext();

const ProvideTransaction = ({ children }) => {
  const transactionProvider = useProvideTransaction();

  return (
    <TransactionContext.Provider value={transactionProvider}>
      {children}
    </TransactionContext.Provider>
  );
};

const useTransaction = () => useContext(TransactionContext);

const useProvideTransaction = () => {
  const [timestamp, setTimestamp] = useState(0);
  const [data, setData] = useState('loading');

  const getStatus = (expireDate, currentStatus) => {
    if (
      currentStatus === 'Sedang Diproses' ||
      currentStatus === 'Menunggu Pembayaran'
    ) {
      return isBefore(expireDate, timestamp || Date.now())
        ? 'Transaksi Kadaluarsa'
        : currentStatus;
    }

    return currentStatus;
  };

  const firestore = getFirestore();

  useEffect(() => {
    setTimestamp(Date.now());

    const loop = setInterval(() => {
      setTimestamp(Date.now());
    }, 60 * 1000);
    return () => clearInterval(loop);
  }, []);

  useEffect(() => {
    if (timestamp === 0) return;
    if (data === 'loading') return;
    if (data.length === 0) return;

    const newData = data
      .map((doc) => {
        return {
          id: doc.id,
          ...doc,
          payment: {
            ...doc.payment,
            status: getStatus(doc.payment.expiredAt, doc.payment.status),
          },
        };
      })
      .sort((a, b) => b.payment.createdAt - a.payment.createdAt)
      .filter((row) => row.payment.status !== 'Sedang Diproses');

    setData(newData);
  }, [timestamp]);

  useEffect(() => {
    const docsRef = collection(firestore, 'transactions');

    const unsubscribe = onSnapshot(docsRef, (snapshot) => {
      if (snapshot.empty) return setData([]);

      const transactionData = snapshot.docs
        .map((doc) => {
          const docData = doc.data();

          return {
            id: doc.id,
            ...docData,
            payment: {
              ...docData.payment,
              status: getStatus(
                docData.payment.expiredAt,
                docData.payment.status
              ),
            },
          };
        })
        .sort((a, b) => b.payment.createdAt - a.payment.createdAt)
        .filter((row) => row.payment.status !== 'Sedang Diproses');

      setData(transactionData);
    });

    return () => unsubscribe();
  }, []);

  return { data };
};

export { ProvideTransaction, useTransaction };
