import { SearchIcon } from '@chakra-ui/icons';
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Flex,
  InputGroup,
  IconButton,
  InputLeftElement,
  Input,
  useDisclosure,
} from '@chakra-ui/react';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { toast, ToastContainer } from 'react-toastify';
import CardHeader from 'components/Card/CardHeader.js';
import DaftarPenggunaRow from 'components/Tables/DaftarPenggunaRow';
import EditUserModal from 'components/Modal/EditUserModal';
import React, { useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { userAPI } from 'utils/userAPI';

const DaftarPenggunaTable = ({ title, captions, data, onSearch }) => {
  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const textColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('white', 'gray.800');
  const mainTeal = useColorModeValue('teal.300', 'teal.300');
  const mainText = useColorModeValue('gray.700', 'gray.200');
  const searchIcon = useColorModeValue('gray.700', 'gray.200');

  const [modalData, setModalData] = useState({
    id: '',
    emailPengguna: '',
    namaPengguna: '',
    status: '',
    role: '',
  });

  const handleSearch = (e) => {
    onSearch(e.target.value);
  };

  const handleModalEditClose = () => {
    setModalData({
      id: '',
      namaPengguna: '',
      emailPengguna: '',
      status: '',
      role: '',
    });
    onClose();
  };

  const handleEdit = (index) => () => {
    const newModalData = {
      id: data[index].id,
      namaPengguna: data[index].name,
      emailPengguna: data[index].email,
      status: data[index].status,
      role: data[index].role,
    };

    setModalData(newModalData);
    onOpen();
  };

  const handleDelete = (index) => async () => {
    const api = new userAPI();
    const [error] = await api.remove(data[index].id, user.token);

    if (error)
      return toast.error('Gagal menghapus data', { toastId: 'toast-result' });

    return toast.success('Berhasil menghapus data', {
      toastId: 'toast-result',
    });
  };

  return (
    <>
      <Card>
        <CardHeader p="6px 0px 22px 0px">
          <Flex
            justifyContent="space-between"
            direction={{ sm: 'column', md: 'row' }}
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {title}
            </Text>
            <InputGroup
              cursor="pointer"
              bg={inputBg}
              borderRadius="15px"
              mt={{ sm: '8px' }}
              w={{
                sm: '100%',
                md: '200px',
              }}
              _focus={{
                borderColor: { mainTeal },
              }}
              _active={{
                borderColor: { mainTeal },
              }}
            >
              <InputLeftElement>
                <IconButton
                  bg="inherit"
                  borderRadius="inherit"
                  _hover={{}}
                  _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                ></IconButton>
              </InputLeftElement>
              <Input
                onChange={handleSearch}
                fontSize="xs"
                py="11px"
                color={mainText}
                placeholder="Cari email pengguna..."
                borderRadius="inherit"
              />
            </InputGroup>
          </Flex>
        </CardHeader>
        <CardBody overflowX={{ sm: 'auto', xl: 'auto' }}>
          <Table variant="simple" color={textColor} whiteSpace="nowrap">
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? '0px' : null}
                    >
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                return (
                  <DaftarPenggunaRow
                    key={row.id + index}
                    name={row.name}
                    avatar={row.avatar}
                    email={row.email}
                    role={row.role}
                    onEdit={handleEdit(index)}
                    onDelete={handleDelete(index)}
                    status={row.status}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      <EditUserModal
        isOpen={isOpen}
        onClose={handleModalEditClose}
        token={user.token}
        data={modalData}
      />

      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default DaftarPenggunaTable;
